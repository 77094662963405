import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/index.vue";

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title: '皓润新能源科技',
      }
    },
  ],
});

Vue.use(VueRouter);

export default router;
